<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b">
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Hak Akses Pengguna</b> yang ada di {{ clinic_name }}
      </div>
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-6">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-start mt-3">
              <div class="col-md-6">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Berdasar Nama"
                    @keyup="filterByName"></b-form-input>
                </b-input-group>
              </div>
            </div>

            <!-- Tabel Dokter di sebelah kiri -->
            <b-tabs
              class="mt-4"
              v-model="tabPosition">
              <b-tab
                title="Daftar Dokter"
                active
                @click="changeTable(0)"></b-tab>
              <b-tab
                title="Daftar Karyawan"
                @click="changeTable(1)"></b-tab>
            </b-tabs>
            <!-- Table -->
            <b-table
              striped
              hover
              class="mt-3"
              :items="itemsDoctor"
              :fields="fieldsDoctor"
              responsive>
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '15%' : '' }" />
              </template>
              <template #cell(actions)="data">
                <!-- <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover.right
                  title="Pilih"
                  @click="getAccessByUser(data.item.user_id)"
                >
                  <i class="fas fa-eye px-0"></i>
                </b-button> -->
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  @click="getAccess(data.item.user_id)">
                  Pilih
                </b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="itemsDoctor.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClickDoctor"
              class="mt-4"></b-pagination>
          </template>
        </Card>
      </div>

      <!-- Tabel Hak Akses di sebelah kanan-->
      <div class="col-md-6">
        <Card style="height: 600px; overflow-y: scroll">
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-start mt-3">
              <div class="col-md-12">
                <h5>Hak Akses</h5>
                <h6>{{ username }}</h6>
              </div>
            </div>

            <div class="row justify-content-start mt-3">
              <div class="col-md-6">
                <b-form-input
                  id="filter-input"
                  v-model="filterAccess"
                  type="search"
                  placeholder="Cari Hak Akses"></b-form-input>
              </div>
            </div>

            <!-- Table -->
            <b-table
              hover
              class="mt-3"
              :items="access_lists_display"
              :fields="fieldAccess"
              id="my-table"
              :filter="filterAccess"
              responsive>
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '15%' : '' }" />
              </template>
              <template #cell(code)="data">
                <span v-if="data.item.parent_id == 0">
                  <b>{{ data.item.name }}</b>
                </span>
                <span v-if="data.item.parent_id !== 0">
                  {{ data.item.code }}
                </span>
              </template>
              <template #cell(name)="data">
                <span v-if="data.item.parent_id == 0"> </span>
                <span v-if="data.item.parent_id !== 0">
                  {{ data.item.name }}
                </span>
              </template>
              <template #cell(actions)="data">
                <span v-if="data.item.parent_id == 0"> </span>
                <span v-if="data.item.parent_id !== 0">
                  <b-button
                    size="sm"
                    v-if="data.item.access_status == true && !data.item.access_status_position"
                    @click="detachUser(data.item.id)"
                    class="mr-1 btn-success"><i class="fas fa-check px-0"></i></b-button>
                  <b-button
                    size="sm"
                    v-if="data.item.access_status_position == true && data.item.access_status == true"
                    class="mr-1 btn-secondary"
                    disabled><i class="fas fa-check px-0"></i></b-button>
                  <b-button
                    size="sm"
                    v-if="!data.item.access_status"
                    @click="attachUser(data.item.id)"
                    class="mr-1 btn-danger"><i class="fas fa-times px-0"></i></b-button>
                </span>
              </template>
            </b-table>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>

import Card from "@/view/content/Card.vue";
import { debouncer } from "@/core/modules/Helper.js"
import module from "@/core/modules/CrudModule.js";
import moduleSaas from "@/core/modules/CrudSaasModule.js";
import api from "@/core/services/api.service.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { getUser, setUser } from '@/core/services/jwt.service.js'

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
      },
      filterAccess: "",
      // display left table
      selected: 1,
      tabPosition: 0,
      doctorTable: true,
      employeeTable: false,
      options: [
        { value: 1, text: "Daftar Dokter" },
        { value: 2, text: "Daftar Karyawan" },
      ],
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      perPageAccess: 10,
      currentPageAccess: 1,
      totalRowsAccess: 1,
      // Table Head
      fieldsDoctor: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "polyclinic_name",
          label: "Poliklinik",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      fieldsEmployee: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      fieldAccess: [
        {
          key: "code",
          label: "Kode",
          sortable: true,
        },
        {
          key: "name",
          label: "Fitur / Modul Sistem",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items Doctor
      itemsDoctor: [],
      // Table Items Employee
      itemsEmployee: [],
      // Table Items Access List
      access_lists: [],
      access_lists_children: [],
      access_lists_display: [],
      //
      access_by_user: [],
      // user name
      username: "",
      user_id: "",
      clinic_name: window.configuration == null ? "Medhop" : window.configuration.clinic_name
    };
  },

  methods: {
    changeTable(id) {
      if (id == 0) {
        this.pagination("doctors");
        this.setFieldDoctor();
        this.employeeTable = false;
        this.doctorTable = true;
      } else if (id == 1) {
        this.pagination("employees");
        this.setFieldEmployee();
        this.doctorTable = false;
        this.employeeTable = true;
      }
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsAccess = filteredItems.length;
      this.currentPage = 1;
    },

    resetArrayOnNew() {
      this.access_lists = [];
      this.access_lists_children = [];
      this.access_lists_display = [];
      this.access_by_user = [];
    },

    setFieldDoctor() {
      this.fieldsDoctor = [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "polyclinic_name",
          label: "Poliklinik",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ];
    },

    setFieldEmployee() {
      this.fieldsDoctor = [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "position_name",
          label: "Jabatan",
          sortable: true,
        },
        {
          key: "department_name",
          label: "Departemen",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ];
    },

    // function tabel dokter di sebelah kiri
    async pageOnClickDoctor(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      await this.paginationDoctor();
    },

    async pagination(route = "doctors") {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        route,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.itemsDoctor = response.data;
      this.totalRows = pagination.total;
    },

    async paginationDoctor() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        "doctors",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.itemsDoctor = response.data;
      this.totalRows = pagination.total;
    },

    async allAccess() {
      let response = await module.paginate(
        "access-lists",
      );
      console.log("allaccess", response)
    },

    // function tabel karyawan di sebelah kiri
    async pageOnClickEmployee(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      await this.paginationEmployee();
    },

    async paginationEmployee() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        "employees",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.itemsEmployee = response.data;
      this.totalRows = pagination.total;
    },

    filterByName() {
      debouncer(() => {
        if (this.doctorTable == true) {
          this.pagination("doctors");
        } else if (this.employeeTable == true) {
          this.pagination("employees");
        }
      }, 500)
    },

    // function tabel hak akses sebelah kanan
    async getAccessList() {
      let response = await module.paginate(
        "access-lists"
      );
      this.access_lists = response.data;
      console.log("default", this.access_lists);

      this.access_lists_display = [];
      let a, b, c;
      for (a = 0; a < this.access_lists.length; a++) {
        this.access_lists_children = response.data[a].children;
        this.access_lists_display.push(this.access_lists[a]);
        for (b = 0; b < this.access_lists_children.length; b++) {
          this.access_lists_display.push(this.access_lists_children[b]);
          for (c = 0; c < this.access_lists_display.length; c++) {
            if (this.access_lists_display[c].parent_id !== 0) {
              this.access_lists_display[c].number = "-";
            }
          }
        }
      }
      let pagination = response.meta.pagination;
      // this.totalRows = pagination.total;
      console.log("hasil", this.access_lists_display);
    },

    getAccess(id) {
      this.resetArrayOnNew()
      this.getAccessByUser(id)
    },

    async getAccessByUser(id) {
      // this.resetArrayOnNew();

      // get access by user
      let access_by_user = await module.getAccessByUser("users/" + id);
      if (access_by_user.data.polyclinic_name) {
        this.username = access_by_user.data.name + " - " + access_by_user.data.polyclinic_name;
      }
      this.user_id = access_by_user.data.user_id;
      let access_right = access_by_user.meta.access_right.split(",");
      console.log("acc_by_user", access_by_user);

      // get access by position
      if (access_by_user.data.position_id) {
        let access_by_position = await module.getAccessByUser("positions/" + access_by_user.data.position_id);
        this.username = access_by_user.data.name + " - " + access_by_user.data.position_name + " - " + access_by_user.data.department_name
        var access_right_position = access_by_position.meta.access_right.split(",")
        console.log("acc_by_position", access_by_position);
      }

      let filterParams = `&name=${this.filter.name}`;
      let response = await moduleSaas.paginate(
        "multitenant-access-lists",
        `?page=${this.currentPage}&page_size=200${filterParams}`
      );
      this.access_lists = response.data;
      console.log("default", this.access_lists);

      this.access_lists_display = [];
      let a, b, c, d, e;
      for (a = 0; a < this.access_lists.length; a++) {
        this.access_lists_children = response.data[a].children;
        this.access_lists_display.push(this.access_lists[a]);
        for (b = 0; b < this.access_lists_children.length; b++) {
          this.access_lists_display.push(this.access_lists_children[b]);
          for (c = 0; c < this.access_lists_display.length; c++) {
            if (this.access_lists_display[c].parent_id !== 0) {
              this.access_lists_display[c].number = "-";
            }
            for (d = 0; d < access_right.length; d++) {
              if (this.access_lists_display[c].code == access_right[d]) {
                this.access_lists_display[c].access_status = true;
              }
            }
            if (access_by_user.data.position_id) {
              for (e = 0; e < access_right_position.length; e++) {
                if (this.access_lists_display[c].code == access_right_position[e]) {
                  this.access_lists_display[c].access_status_position = true
                  this.access_lists_display[c].access_status = true
                }
              }
            }
          }
        }
        console.log("hasil akhir", this.access_lists_display);
      }
    },

    async attachUser(id) {
      let targetUser = this.user_id
      let done = await api
        .post("access-lists/" + id + "/" + this.user_id + "/attach/user")
        .then((response) => {
          this.getAccessByUser(this.user_id);
          // if (targetUser == getUser().user_id) {
          //   localStorage.setItem('access_right', response.data.access_right)
          //   localStorage.setItem('access_right_display', JSON.stringify(response.data.access_right_display))
          //   return true
          // }
        });
      if (done) {
        // this.$root.$emit('setMenuActive');
        // this.$root.$emit('UpdateSideMenu');
      }

      //  await api
      //   .post("access-lists/" + id + "/" + this.user_id + "/attach/user")
      //   .then((response) => {
      //     console.log("attach user", response);
      //     this.getAccessByUser(this.user_id);
      //     this.$root.$emit("setMenuActive");
      //   });
    },

    async detachUser(id) {
      let targetUser = this.user_id
      let done = await api
        .delete("access-lists/" + id + "/" + this.user_id + "/detach/user")
        .then((response) => {
          this.getAccessByUser(this.user_id);
          // if (targetUser == getUser().user_id) {
          //   localStorage.setItem('access_right', response.data.access_right)
          //   localStorage.setItem('access_right_display', JSON.stringify(response.data.access_right_display))
          //   return true
          // }
        });
      if (done) {
        // this.$root.$emit('setMenuActive');
        // this.$root.$emit('UpdateSideMenu');
      }

      //  await api
      //   .delete("access-lists/" + id + "/" + this.user_id + "/detach/user")
      //   .then((response) => {
      //     console.log("detach user", response);
      //     this.getAccessByUser(this.user_id);
      //     this.$root.$emit("setMenuActive");
      //   });
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("action-types/" + id);
      // If Deleted
      if (result) {
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Hak Akses User" }]);
    // Get Data
    // this.getAccessList();
    this.paginationDoctor();
    this.allAccess()
  },
};
</script>
